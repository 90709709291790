const useErrorHandling = () => {
  const sanitizeGraphQLMessage = (message) => {
    let sanitizedMessage = message.replace("GraphQL error: ", "");
    if (typeof sanitizedMessageMapper[sanitizedMessage] !== "undefined") {
      return sanitizedMessageMapper[sanitizedMessage];
    }
    return sanitizedMessage;
  };

  return { sanitizeGraphQLMessage };
};

/**
 * TODO:
 * Move mapper outside of hook, or maybe create a module so that we can map directly from Magento and make sure that we have localization
 */
const sanitizedMessageMapper = {
  "The current user cannot perform operations on wish list":
    "Your session has expired. Please login and try again.",
  "Exception occurred: options missing":
    "Unable to add product to cart. Required product options are missing.",
  "Exception occurred: out of stock":
    "Unable to add product to cart. Product is out of stock.",
};

export default useErrorHandling;
