export const normalizeRoutes = (Pages) => {
  let pagesRemap = {};
  Pages.map((page) => {
    let key = page.url;

    if (typeof pagesRemap[key] === "undefined") {
      pagesRemap[key] = { ...page };
    }

    return true;
  });

  return pagesRemap;
};
